 /* imghvr-hinge-*
   ----------------------------- */
[class^='imghvr-hinge-'],[class*=' imghvr-hinge-'] {
	@include perspective(50em);
	.caption {
		opacity: 0;
		z-index: 1;
	}
	&:hover {
		img {
			opacity: 0;
		}
		.caption {
			opacity: 1;
			@include transition-delay($transition-duration * 0.6);
		}
	}
}
 /* imghvr-hinge-up
   ----------------------------- */
.imghvr-hinge-up {
	img {
		@include transform-origin(50% 0%);
	}
	.caption {
		@include transform(rotateX(90deg));
		@include transform-origin(50% 100%);
	}
	&:hover {
		> img {
			@include transform(rotateX(-90deg));
		}
		.caption {
			@include transform(rotateX(0));
		}
	}
}
 /* imghvr-hinge-down
   ----------------------------- */
.imghvr-hinge-down {
	img {
		@include transform-origin(50% 100%);
	}
	.caption {
		@include transform(rotateX(-90deg));
		@include transform-origin(50% -50%);
	}
	&:hover {
		> img {
			@include transform(rotateX(90deg));
			opacity: 0;		}
		.caption {
			@include transform(rotateX(0));
		}
	}
}
 /* imghvr-hinge-left
   ----------------------------- */
.imghvr-hinge-left {
	img {
		@include transform-origin(0% 50%);
	}
	.caption {
		@include transform(rotateY(-90deg));
		@include transform-origin(100% 50%);
	}
	&:hover {
		> img {
			@include transform(rotateY(90deg));
		}
		.caption {
			@include transform(rotateY(0));
		}
	}
}
 /* imghvr-hinge-right
   ----------------------------- */
.imghvr-hinge-right {
	img {
		@include transform-origin(100% 50%);
	}
	.caption {
		@include transform(rotateY(90deg));
		@include transform-origin(0 50%);
	}
	&:hover {
		> img {
			@include transform(rotateY(-90deg));
		}
		.caption {
			@include transform(rotateY(0));
		}
	}
}