 /* imghvr-reveal-*
   ----------------------------- */
[class^='imghvr-reveal-'],[class*=' imghvr-reveal-'] {
	&:before {
		@include fill;
		content: '';
		background-color: $figcaption-bg;
	}
	.caption {
		opacity: 0;
	}
	&:hover {
		&:before {
			@include transform(translate(0,0));
		}
		.caption {
			opacity: 1;
			@include transition-delay($transition-duration * 0.6);
		}
	}
}
 /* imghvr-reveal-up
   ----------------------------- */
.imghvr-reveal-up {
	&:before {
		@include transform(translateY(100%));
	}
}
 /* imghvr-reveal-down
   ----------------------------- */
.imghvr-reveal-down {
	&:before {
		@include transform(translateY(-100%));
	}
}
 /* imghvr-reveal-left
   ----------------------------- */
.imghvr-reveal-left {
	&:before {
		@include transform(translateX(100%));
	}
}
 /* imghvr-reveal-right
   ----------------------------- */
.imghvr-reveal-right {
	&:before {
		@include transform(translateX(-100%));
	}
}

 /* imghvr-reveal-top-left
   ----------------------------- */
.imghvr-reveal-top-left {
	&:before {
		@include transform(translate(-100%,-100%));
	}
}
 /* imghvr-reveal-top-right
   ----------------------------- */
.imghvr-reveal-top-right {
	&:before {
		@include transform(translate(100%,-100%));
	}
}
 /* imghvr-reveal-bottom-left
   ----------------------------- */
.imghvr-reveal-bottom-left {
	&:before {
		@include transform(translate(-100%,100%));
	}
}
 /* imghvr-reveal-bottom-right
   ----------------------------- */
.imghvr-reveal-bottom-right {
	&:before {
		@include transform(translate(100%,100%));
	}
}