@mixin pos_lt ($left:0,$top:0){
    position: absolute;
    left: #{$left}px;
    top: #{$top}px;
}
@mixin pos_lb ($left:0,$bottom:0){
    position: absolute;
    left: #{$left}px;
    top: #{$bottom}px;
}
@mixin pos_rt ($right:0,$top:0){
    position: absolute;
    right: #{$right}px;
    top: #{$top}px;
}
@mixin pos_rb ($right:0,$bottom:0){
    position: absolute;
    right: #{$right}px;
    bottom: #{$top}px;
}
@mixin pos ($left:0,$top:0,$right:0,$bottom:0){
    position: absolute;
    left: #{$left}px;
    top: #{$top}px;
    right: #{$right}px;
    bottom: #{$top}px;
}