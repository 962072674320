//mixins
@import "mixins/include.scss";

//variables
//@import "_variables.scss";
@import "_reset.scss";

//header
@import "_header.scss";

//footer
@import "_footer.scss";

//sidebar
@import "_sidebar.scss";

//common
@import "_layout.scss";
@import "_parts.scss";

//style
@import "style2.scss";

//pages
@import "_home.scss";
@import "page/_rs_comment.scss";
@import "page/_proposal.scss";
@import "page/_event.scss";
@import "page/_about.scss";
@import "page/_other.scss";


//print
@import "_print.scss";
