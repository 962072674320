.event_wrap{
	.category{
		padding-bottom:0.5em;
	}
	.thumb{
		margin-bottom:1em;
	}
	.text_wrap{
		.date{
			text-align:right;
		}
	}
}

.list_topics.archive_event .box{
	width:32%;
	@include sp(){
		width:100%;
	}
}
