header{
	border-bottom:1px solid rgba(32,33,36,0.10);
	@include sp(){
		.top_box{
			.logo{
				padding-right:45px;
				margin-bottom:10px;
			}
			.btn{
				display:none;
			}
		}
		.btn_menu_open{
			position:absolute;
			right: 15px;
			top: 5px;
			z-index: 999;
			.menu_open{
				font-size: 40px;
				line-height: 1;
				display: inline-block;
				color: #202124;
			}
		}
		.sp_menu_wrap{
			position:absolute;
			right: 15px;
			top: 5px;
			z-index: 999;
			width: 280px;
			visibility: hidden;
			transition: .5s;
			opacity: 0;
			transform: translateY(20px);
			&.open{
				transform: translateY(0px);
				opacity: 1;
				visibility: visible;
			}
			.btn_menu_close{
				text-align:right;
				.menu_close{
					font-size: 40px;
					line-height: 1;
					background-color: RGBA(255, 255, 255, 0.7);
					padding: 0px 5px;
					border-radius: 5px;
					display: inline-block;
					color: #202124;
				}
			}
			.sp_btn{
				text-align:right;
				margin:10px 0px;
				a{
					display:inline-block;
					padding:3px 10px;
					height:auto;
				}
			}
			.loggin{
				text-align:right;
				margin:10px 0px;
			}
			.container{
				background: rgba(0, 0, 0, 0.8);
				box-shadow: 0 10px 20px 0px rgba(0, 0, 0, 0.3);
				border-radius: 4px;
				//overflow-y: scroll;
				padding:15px;
				.hnav{
					ul{
						border-top: 1px solid rgba(255, 255, 255, 0.15);
						li{
							border-bottom: 1px solid rgba(255, 255, 255, 0.15);
							a{
								color: #FFFFFF;
								padding: 10px;
								display: block;
								text-decoration: none;
								&:before{
									content: "\f0a9";
									font-family: FontAwesome;
									color: #FF6008;
									padding-right: 10px;
								}
							}
							ul.child{
								li{
									padding-left:1.5em;
									a{
										&:before{
											content: "\f178";
											font-family: FontAwesome;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	@include tab(){
		height:130px;
		.btn_menu_close,
		.sp_btn{
			display:none;
		}
		.top_box{
			height:30px;
			background-color:#F2F3F4;
			.logo{
				position:absolute;
				left:0px;
				top:0px;
				z-index:10;
			}
			.btn{
				position:absolute;
				right:15px;
				top:5px;
			}
		}
		.search_wrap{
			position:absolute;
			right:15px;
			top:10px;
		}
		.hnav_wrap{
			position: absolute;
			right: 15px;
			top: 72px;
			.loggin{
				float:right;
				margin-left:1em;
				.btn_style03{
					padding: 2px 15px;
				}
			}
		}
		.hnav{
			/*position:absolute;
			right:15px;
			top:75px;*/
			@include clearfix();
			float:right;
			font-size:88%;
			span{
				content: "";
				background: #FF6008;
				height: 2px;
				display: block;
				position: relative;
				width: 0px;
				left: 0;
				animation: navbar_fadein 2s ease;
                animation-fill-mode:forwards;
			}
			@keyframes navbar_fadein {
				0% {opacity:0;}
				20% {opacity:0.01;}
				100% {opacity:1;}
			}
			@-webkit-keyframes navbar_fadein {
				0% {opacity:0;}
				20% {opacity:0.01;}
				100% {opacity:1;}
			}
			@-ms-keyframes navbar_fadein {
				0% {opacity:0;}
				20% {opacity:0.01;}
				100% {opacity:1;}
			}
			ul{
				@include clearfix();
				padding:3px 0px;
				li{
					float:left;
					margin-left:20px;
					position:relative;
					a{
						color:#202124;
						text-decoration:none;
					}
					ul.child{
						position:absolute;
						top:20px;
						right:0px;
						z-index:100;
						min-width:15em;
						background: rgba(0, 0, 0, 0.8);
						box-shadow: 0 10px 20px 0px rgba(0, 0, 0, 0.3);
						border-radius: 4px;
						padding:15px;
						transition: .5s;
						opacity: 0;
						visibility: hidden;
						transform: translateY(20px);
						&.open{
							transform: translateY(0px);
							opacity: 1;
							visibility: visible;
						}
						li{
							float:none;
							border-bottom: 1px solid rgba(255, 255, 255, 0.15);
							margin-left:0px;
							a{
								color: #FFFFFF;
								padding: 10px 10px 10px 1.5em;
								display: block;
								text-decoration: none;
								position:relative;
								white-space: nowrap;
								&:before{
									content: "\f0a9";
									font-family: FontAwesome;
									color: #FF6008;
									padding-right: 10px;
									position:absolute;
									left:0;
									top:50%;
									margin-top:-0.8em;
									transform: translateZ(0);
									transition-duration: 0.1s;
									transition-property: transform;
									transition-timing-function: ease-out;
								}
								&:hover{
									&:before{
										transform: translateX(4px);
									}
								}

							}
						}
					}
				}
			}
		}
	}
	
	/*@include tab(){
		body.home & .hnav span{width: 38px;left: 0px;}
		body.post-type-archive-news & .hnav span{width: 52px; left: 63px;}
		body.post-type-archive-proposal & .hnav span{width: 117px; left: 140px;}
		body.post-type-archive-rs_comment & .hnav span{width: 104px; left: 282px;}
		body.post-type-archive-event & .hnav span{width: 130px; left: 411px;}
		body.page-template-tmp-about-php & .hnav span{width: 117px; left: 566px;}
	}
	@include pc_sm(){
		body.home & .hnav span{width: 41px;left: 0px;}
		body.post-type-archive-news & .hnav span{width: 56px; left: 66px;}
		body.post-type-archive-proposal & .hnav span{width: 126px; left: 147px;}
		body.post-type-archive-rs_comment & .hnav span{width: 112px; left: 298px;}
		body.post-type-archive-event & .hnav span{width: 140px; left: 435px;}
		body.page-template-tmp-about-php & .hnav span{width: 126px; left: 600px;}
	}
	@include pc(){
		body.home & .hnav span{width: 47px;left: 0px;}
		body.post-type-archive-news & .hnav span{width: 64px;left: 72px;}
		body.post-type-archive-proposal & .hnav span{width: 144px; left: 161px;}
		body.post-type-archive-rs_comment & .hnav span{width: 128px; left: 330px;}
		body.post-type-archive-event & .hnav span{width: 160px; left: 483px;}
		body.page-template-tmp-about-php & .hnav span{width: 144px; left: 668px;}
	}*/
	
}

.search_box{
	display:table;
	.search_icon{
		display:table-cell;
		vertical-align:middle;
		padding-left:10px;
		border-top:1px solid #DEDDDE;
		border-bottom:1px solid #DEDDDE;
		border-left:1px solid #DEDDDE;
		border-radius:4px 0px 0px 4px;
		background-color:#FFFFFF;
		.fa{
			color:#CBCBCB;
			font-size:100%;
		}
	}
	.search_text{
		display:table-cell;
		vertical-align:middle;
		border-top:1px solid #DEDDDE;
		border-bottom:1px solid #DEDDDE;
		background-color:#FFFFFF;
		input{
			border:none;
			font-size:100%;
			padding:3px 10px;
			width:100%;
		}
	}
	.search_submit{
		display:table-cell;
		vertical-align:middle;
		background-color:#B0AEB0;
		border-radius:0px 4px 4px 0px;
		input{
			border:none;
			background-color:transparent;
			color:#FFFFFF;
			font-size:75%;
			padding:6px 30px;
			cursor:pointer;
			@include sp(){
				padding:6px 10px;
			}
		}
	}
}



#member{
	background:url(../images/member_bg.png) left top no-repeat #FFFFFF;
	background-size:100% auto;
	.member_btn{
		a{
			display:inline-block;
			color:#202124;
			border:2px solid #202124;
			background-color:#FFFFFF;
			border-radius:40px;
			padding:0.5em;
			text-align:center;
			width:100%;
			font-size:113%;
			text-decoration:none;
			transition:all 0.2s;
			&:hover{
				background-color:#FF6008;
				color:#FFFFFF;
				border:2px solid #FF6008;
			}
		}
	}

	@include sp(){
		.member_in{
			padding:20px;
			.member_subtitle{
				font-size:125%;
				margin:1em 0;
			}
			.member_logo{
				display:none;
			}
			.member_btn{
				width:100%;
				margin-top:1em;
			}
		}
	}
	@include tab(){
		padding:3em 0;
		width:690px;
		.member_in{
			width:75%;
			position:relative;
			margin-left:auto;
			margin-right:auto;
			.member_subtitle{
				color:#FFFFFF;
				text-align:center;
				font-size:150%;
				margin:1em 0;
			}
			.member_logo{
				margin-top:5em;
				width:30%;
			}
			.member_btn{
				width:40%;
				position:absolute;
				right:0px;
				bottom:0px;
			}
		}
	}
	@include pc_sm(){
		width:882px;
	}
	@include pc(){
		width:1080px;
	}

}
