/**** pageNavi ****/
.pagination_top{
	margin-bottom:30px;
}
.pagination_bottom{
	margin:30px 0px;
}
.pageNaviWrap{
	@include clearfix();
}
.pageNavi{
}
.pageNavi span,
.pageNavi a{
	display:block;
	float:left;
	text-decoration:none;
	height:32px;
	line-height:32px;
	text-align:center;
	transition:all 0.2s;
}
.pageNavi .inactive,
.pageNavi .current,
.pageNavi .arrow{
	width:32px;
	font-size:100%;
}
.pageNavi a.inactive,
.pageNavi .arrow{
	border:1px solid #E1E1E1;
	background-color:#FFFFFF;
	color:#000000;
}
.pageNavi .current,
.pageNavi .arrow:hover,
.pageNavi .inactive:hover,
.pageNavi .next:hover .text,
.pageNavi .back:hover .text{
	border:1px solid #FF6008;
	background-color:#FF6008;
	color:#FFFFFF;
}
.pageNavi .text{
	width:47px;
	border:1px solid #DEF1E2;
	background-color:#FFFFFF;
	color:#BFE4C7;
	font-size:71%;
	-webkit-transition:0.5s;
	-moz-transition:0.5s;
	-o-transition:0.5s;
	transition:0.5s;
}
.space{
	color:#000000;
	font-size:84%;
}
.pageNavi .inactive,
.pageNavi .current,
.pageNavi .back .text,
.space,
.arrow{
	margin-right:6px;
}
