/**** inqury ****/
.inqury_wrap{
	@include tab(){
		margin-left:auto;
		margin-right:auto;
		width:100%;
	}
	@include pc_sm(){
		width:75%;
	}
	input[type="text"]{
		font-size: 100%;
		padding: 0.3em;
		width: 100%;
		max-width:100%;
		border:1px solid #CCCCCC;
		@include sp(){
			width: 100%;
		}
	}
	textarea{
		font-size: 100%;
		padding: 0.2em;
		width: 100%;
		height:10em;
		border:1px solid #CCCCCC;
	}
}




/**** 404 ****/
.error_wrap{
	padding:3em 0 5em 0;
}
.text_404{
	font-size:400%;
	color:#FF6008;
	opacity:0.4;
	font-family:Verdana, Geneva, sans-serif;
	font-weight:bold;
	@include sp(){
		font-size:181%;
	}
}


/**** serch ****/
.serch_wrap{
	padding:3em 0 5em 0;
}
.inqury_head{
	display:table;
	width:100%;
	margin-bottom:4em;
	.box{
		display:table-cell;
		width:33%;
		vertical-align:middle;
		text-align:center;
		.flow{
			background-color:#A9BDD5;
			color:#FFFFFF;
			border-right:1px solid #FFFFFF;
			margin-right:2em;
			position:relative;
			height:5em;
			padding-top:1.7em;
			&:after{
				content: "";
				display: block;
				width: 0;
				height: 0;
				border-top: 2.5em solid transparent;
				border-right: 2em solid transparent;
				border-bottom: 2.5em solid transparent;
				border-left: 2em solid #A9BDD5;
				position: absolute;
				right: -4em;
				top: 0;
			}
			span{
				font-size:119%;
			}
		}
		&.cur{
			.flow{
				background-color:#527BAA;
			&:after{
				content: "";
				display: block;
				width: 0;
				height: 0;
				border-top: 2.5em solid transparent;
				border-right: 2em solid transparent;
				border-bottom: 2.5em solid transparent;
				border-left: 2em solid #527BAA;
				position: absolute;
				right: -4em;
				top: 0;
			}
			}
		}
	}
}



/*** リンク集 ****/
.link_wrap{
	@include tab(){
		@include clearfix();
		.link_box{
			float:left;
			width:50%;
		}
	}
}


/*** サイトマップ ***/
.sitemap_wrap{
	ul{
		li{
			border-bottom: 1px dotted #ddd;
			a{
				padding:0.7em;
				display:inline-block;
				color:#202124;
				text-decoration:none;
				&:hover{
					color: #FF6008;
				}
				&:before{
					content: "\f101";
					font-family: FontAwesome;
					color: #FF6008;
					padding-right: 10px;
				}
			}
			.child{
				margin-left:2em;
				margin-bottom:0.7em;
				li{
					border:none;
					a{
						padding:0.3em;
						&:before{
							content: "\f105";
							font-family: FontAwesome;
							color: #FF6008;
							padding-right: 10px;
						}
					}
				}
			}
		}
	}
}



