.list_topics{
	@include clearfix();
	@include tab(){
		margin-left:-6px;
		margin-right:-6px;
	}
	@include pc(){
		margin-left:-10px;
		margin-right:-10px;
	}
	.box{
		.category{
			font-size:84%;
			font-weight:bold;
			.fa{
				color:#FF6008;
			}
		}
		.text_wrap{
			a{
				color: #202124;
				text-decoration:none;
				.date{
					font-size:75%;
				}
				.title{
					font-size:125%;
					font-weight:normal;
				}
				.text{
					font-size:88%;
					color: rgba(32,33,36,0.70);
				}
			}
		}
		.more{
			text-align:right;
			font-size:75%;
			.fa{
				color: #202124;
			}
		}
		@include sp(){
			border-bottom:1px solid rgba(32,33,36,0.10);
			padding:10px 0px;
			.category{
				margin-bottom:5px;
			}
			.thumb{
				width:100px;
				float:left;
				margin-right:10px;
			}
			.text_wrap{
				overflow:hidden;
				margin-bottom:10px;
			}
		}
		@include tab(){
			padding:0px 6px;
			width:33%;
			float:left;
			margin-bottom:40px;
			.category{
				margin-bottom:5px;
				//height:2.5em;
			}
			.thumb{
				margin-bottom:20px;
				overflow:hidden;
				position:relative;
				.text{
					position:absolute;
					left:0;
					bottom:0;
					padding:0.51em;
					color: #202124;
				}
				img{
					/*width:auto;
					height:auto;
					max-width:none;*/
					min-width:100%;
					min-height:100%;
					opacity:0;
				}
			}
			.text_wrap{
				margin-bottom:10px;
				border-bottom:1px solid rgba(32,33,36,0.10);
				.date{
					margin-bottom:5px;
				}
				.title{
					margin-bottom:10px;
				}
				.text{
					margin-bottom:10px;
				}
			}
		}
		@include pc_sm(){
			width:25%;
			/*margin-bottom:50px;
			.category{
				margin-bottom:5px;
			}
			.thumb{
				margin-bottom:20px;
			}
			.text_wrap{
				margin-bottom:10px;
				.date{
					margin-bottom:5px;
				}
				.title{
					margin-bottom:10px;
				}
				.text{
					margin-bottom:10px;
				}
			}*/
		}
		@include pc(){
			padding:0px 10px;
			width:25%;
			margin-bottom:60px;
			.category{
				margin-bottom:10px;
			}
			.thumb{
				margin-bottom:30px;
			}
			.text_wrap{
				margin-bottom:15px;
				.date{
					margin-bottom:10px;
				}
				.title{
					margin-bottom:15px;
				}
				.text{
					margin-bottom:15px;
				}
			}
		}
		
	}
}

.list_link{
	@include clearfix();
	@include sp(){
		li{
			margin-bottom:10px;
		}
	}
	@include tab(){
		margin-left:-6px;
		margin-right:-6px;
		li{
			padding:0px 6px;
			width:33%;
			float:left;
		}
	}
	@include pc_sm(){
		li{
			width:25%;
			float:left;
		}
	}
	@include pc(){
		margin-left:-10px;
		margin-right:-10px;
		li{
			padding:0px 10px;
			width:25%;
			float:left;
		}
	}
}

.list_archive{
	border-top:1px solid #DEDDDE;
	.box{
		border-bottom:1px solid #DEDDDE;
		a{
			text-decoration:none;
			padding:1em 3em 1em 1.5em;
			display:block;
			position:relative;
			transition:all 0.2s;
			&:after{
				content: "\f105";
				font-family: FontAwesome;
				color:#202124;
				position:absolute;
				right:1em;
				top:50%;
				margin-top:-0.5em;
				display:block;
				font-size:163%;
				line-height:1;
				transform: translateZ(0);
				transition-duration: 0.1s;
				transition-property: transform;
				transition-timing-function: ease-out;
			}
			&:hover{
				background-color: #F4F8F9;
				&:after{
					transform: translateX(4px);
				}
			}
			.title{
				font-size:150%;
				font-weight:normal;
				color:#202124;
			}
			.text{
				color:#202124;
				opacity:0.7;
			}
		}
	}
}
