#rs_comment_wrap{
	@include sp(){
		.visual{
			display:none;
		}
	}
	
	.rs_comment_visual{
		display:table;
		width:100%;
		.box{
			display:table-cell;
			width:33.333%;
			vertical-align:middle;
			background-color:#47464C;
			transition:all 0.2s;
			&:hover{
				background-color:#2D2C32;
			}
			a{
				padding:0 1.5em;
				text-decoration:none;
				color:#FFFFFF;
				display:block;
				.date{
					font-size:88%;
					color:#D0021B;
					margin-bottom:0.8em;
				}
				.title{
					font-size:144%;
					font-weight:normal;
					margin-bottom:0.5em;
				}
				.text{
					font-size:81%;
				}
			}
		}
	}
	
	//single
	.title_wrap{
		display:table;
		width:100%;
		background-color:#F3F6F8;
		padding:1.5em 2em;
		margin-bottom:2em;
		.title{
			font-size:163%;
			font-weight:normal;
		}
		.date{
			font-size:88%;
			color:#D0021B;
		}
		@include tab(){
			.title_main{
				width:75%;
				display:table-cell;
				vertical-align:middle;
				.category{
					padding-bottom:0.5em;
				}
			}
			.date{
				width:25%;
				display:table-cell;
				vertical-align:middle;
				text-align:right;
			}
		}
	}

}
