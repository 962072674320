.table_style01{
	margin-top: 3em;
	width: 100%;
	border-top: 1px solid #EEEEEE;
	border-left: 1px solid #EEEEEE;
	th{
		padding: 1em;
		vertical-align: middle;
		font-size: 16px;
		font-weight: normal;
		color: #333333;
		border-right: 1px solid #EEEEEE;
		border-bottom: 1px solid #EEEEEE;
		width:15em;
		background-color: #F4F8F9;
		@include sp(){
			display:block;
			width:100%;
			padding:10px;
			box-sizing:border-box;
		}
	}
	td{
		padding: 1em;
		vertical-align: middle;
		font-size: 16px;
		font-weight: normal;
		color: #333333;
		border-right: 1px solid #EEEEEE;
		border-bottom: 1px solid #EEEEEE;
		@include sp(){
			display:block;
			width:100%;
			padding:10px;
			box-sizing:border-box;
		}
	}
}


.table_style02{
	width:100%;
	margin-bottom:3em;
	@include sp(){
		tr{
			display:block;
			padding:0 1em;
			margin-bottom:1em;
			td{
				padding:0 1em;
				display:block;
				&:nth-of-type(1){
				font-weight:bold;
				padding-left:0;
				}
			}
		}
	}
	@include tab(){
		td{
			padding:0.3em 1em;
			line-height:1.3;
			&:nth-of-type(1){
				width:20%;
			}
			&:nth-of-type(2){
				width:36%;
			}
		}
	}
}