@mixin hover($opacity:0.7) {
	img{
		transition: 0.3s;
	}
    &:hover{
        img{
            filter: alpha(opacity=$opacity*100);
            -moz-opacity:$opacity;
            opacity:$opacity;
        }
    }
}

@mixin hover-scale($scale:1.05) {
    img{
        transition: transform .4s cubic-bezier(.34, .1, .43, .95);
    }
    &:hover{
        img{
            transform: scale($scale);
        }
    }
}

$box_shadow_style01 : 0 1px 2px 0 rgba(0, 0, 0, .15);
$box_shadow_style02 : 0 6px 20px 0 rgba(0, 0, 0, .2);
@mixin hover-shadow() {
    @include pc{
        transition: box-shadow .4s;
        box-shadow: $box_shadow_style01;
        &:hover{
           box-shadow: $box_shadow_style02; 
        }
    }
}