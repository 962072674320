@import "mixins/include.scss";
@import "lib/imagehover/imagehover.scss";
/*
#home_top .left .home_nav .box{
    margin-left: 10px;
    padding-left: 0;
    width: 380px;
}
*/
@mixin hover-shine() {
    @include pc{
        position: relative;
        background: #fff;
        overflow: hidden;
        & a:before{
            position: absolute;
            top: 0;
            left: -65%;
            z-index: 2;
            display: block;
            content: '';
            width: 50%;
            height: 100%;
            background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.1) 100%);
            background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.1) 100%);
            -webkit-transform: skewX(-25deg);
            transform: skewX(-25deg);
        }
        & a:hover::before {
            -webkit-animation: shine 1.2s;
            animation: shine 1.2s;
        }
        /*img{
            transition: transform 0.2s ease-in-out 0s;
            transform: scale(1);
        }
        &:hover{
            img{transform: scale(1.08);}
        }*/
        a{
            //position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: 3;
        }
    }
}
@keyframes shine {
    100% {
        left: 125%;
    }
}

/* Circle */
@mixin hover-circle() {
    @include pc{
        position: relative;
        overflow: hidden;
        &:before{
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 2;
            display: block;
            content: '';
            width: 0;
            height: 0;
            background: rgba(255,255,255,.1);
            border-radius: 100%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            opacity: 0;
        }
        &:hover::before{
            -webkit-animation: circle .75s;
            animation: circle .75s;
        }
        a{
            //position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: 3;
        }
    }
}

@keyframes circle {
    0% {
        opacity: 1;
    }
    40% {
        opacity: 1;
    }
    100% {
        width: 200%;
        height: 200%;
        opacity: 0;
    }
}

/* Circle */
@mixin hover-rect($sec:0.4) {
    @include pc{
        position: relative;
        width: 100%;
        margin: 1%;
        background-color: #fff;
        text-align: left;
        &:before,&:after,.caption:before,.caption:after{
            position: absolute;
            content: '';
            background-color: #000;
            z-index: 1;
            opacity: 0.8;
            -webkit-transition: all #{$sec}s ease-in;
            transition: all #{$sec}s ease-in;
        }
        &:before,&:after{
            height: 1px;
            width: 0%;
        }
        &:before{
            top: 0;
            left: 0;
        }
        &:after{
            bottom: 0;
            right: 0;
        }
        *{
            -webkit-transition: opacity #{$sec}s ease-in;
            transition: opacity #{$sec}s ease-in;
        }
        img{
            position: relative;
            vertical-align: top;
            filter: alpha(opacity=100);
            opacity: 1;
            display: inline-block; /* 追加 */
            -webkit-backface-visibility: hidden; /* 追加 */
            backface-visibility: hidden; /* 追加 */
        }
        .caption{
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0;
            padding: 20px 30px;
            &:before,&:after {
                width: 1px;
                height: 0;
            }
            &:before{
                right: 0;
                top: 0;
            }
            &:after{
                left: 0;
                bottom: 0;
            }
        }
        a{
            //position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: 1;
        }
        &:hover{
            &:before,&:after{
                width: 100%;
                opacity: 0.1;
            }
            img{
                opacity: 0.6;
            }
            .caption{
                opacity: 1;
                &:before,&:after {
                    height: 100%;
                    opacity: 0.1;
                }
            }
        }
    }
}

@mixin fade-top() {
    @include pc{
        transform: translateY(60px);
        opacity: 0;
        transition: transform 1.2s cubic-bezier(.175, .885, .32, 1), opacity 1.2s cubic-bezier(.175, .885, .32, 1);
        &.animated{
            transform: translateY(0);
            opacity: 1;
        }

        @for $i from 0 through 10 {
            &.delay#{$i}00 {
                transition-delay: #{$i*0.1}s;
            }
        }
    }
}


.box1{
    @include hover-shine();
}

.list_topics .box{
    @include fade-top();
    margin-bottom: 40px;
    padding-top: 10px;
    padding-bottom: 20px;
    &:hover{
        box-shadow: 0px 1px 3px rgba(0,0,0,0.13);
    }
}

.box2{
    @include hover-circle();
}

.box3{
    @include hover-rect(0.35);
}