#home_top{
	@include clearfix();
	.left{
		width:66%;
		float:left;
		@include sp(){
			width:100%;
			float:none;
			margin-bottom:40px;
		}
		.home_visual{
			margin-bottom:0.7em;
		}
		.home_nav{
			margin-left:-0.7em;
			@include clearfix();
			.box{
				width:50%;
				float:left;
				padding-left:0.7em;
			}
		}
	}
	.right{
		width:32%;
		float:right;
		@include sp(){
			width:100%;
			float:none;
		}
		.btn{
			margin-bottom:20px;
			@include pc(){
				margin-bottom:30px;
			}
		}
	}
}

#home_news{
	margin-bottom:2em;
	ul{
		border-bottom:1px solid rgba(32,33,36,0.10);
		li{
			@include tab(){
				display:none;
				&:first-child{
					display:block;
				}
			}
			@include pc_sm(){
				display:block;
				&:nth-last-of-type(1){
					display:none;
				}
			}
			@include pc(){
				&:nth-last-of-type(1){
					display:block;
				}
			}
			a{
				color: #202124;
				text-decoration:none;
				transition-duration: 0.3s;
				display:block;
				padding:0.5em 0;
				&:hover{
					background-color: #F4F8F9;
				}
			}
			.date{
				font-size:75%;
				font-weight:bold;
				margin-bottom:5px;
			}
			.text{
			}
		}
	}
	.more{
		margin-top:30px;
		a.btn_news_more{
			display:block;
			text-align:center;
			color: #202124;
			text-decoration:none;
			.fa{
				display:block;
			}
		}
	}
}


#home_topics{
	@include tab(){
		position:relative;
		.btn{
			position:absolute;
			right:0px;
			top:-3px;
		}
	}
}


#home_tag{
	background-color: #F2F3F4;
	padding:15px 25px;
	.title{
		color:#333333;
		font-size:113%;
		font-weight:normal;
		margin-bottom:10px;
	}
	ul{
		@include clearfix();
		li{
			font-size:75%;
			float:left;
			margin-right:5px;
			margin-bottom:10px;
			a{
				color:#202124;
				text-decoration:none;
				display:block;
				padding:5px 20px;
				border-radius:4px;
				background-color: #B0AEB0;
				color:#FFFFFF;
				transition:all 0.1s;
				&:hover{
					background-color: #FF6008;
				}
			}
		}
	}
}


#home_gmap{
	.address{
		text-align:right;
	}
}


.gmap{
	width:100%;
	height:200px;
	margin-bottom:10px;
	@include tab(){
		height:300px;
	}
	@include pc_sm(){
		height:400px;
	}
	@include pc(){
		height:600px;
	}
}




/******** B案 *******/
	#home_news .more{
		margin:10px 20px;
	}
	#home_news ul li .text{
		font-size:88%;
	}
	#home_news .more{
		font-size:88%;
	}
	.list_link2{
		li{
			margin-bottom:5px;
			text-align:center;
			a{
				@include hover();
			}
		}
	}
	#home_gmap{
		@include tab(){
			@include clearfix();
			.left{
				float:left;
				width:60%;
			}
			.right{
				float:right;
				width:40%;
				padding-left:20px;
			}
			.gmap{
				@include tab(){
					height:300px;
				}
				@include pc_sm(){
					height:350px;
				}
				@include pc(){
					height:450px;
				}
			}
		}
		.address{
			text-align:left;
			p{
				margin-bottom:2em;
			}
		}
		table{
			font-size:81%;
				line-height:2em;
			th{
				width:6em;
			}
			td{
			}
		}
	}


/*** slider ***/
#slider{
	li{
		position:relative;
		span{
			width:62%;
			position:absolute;
			transition:all 3s;
			&.text_1{
				left:6%;
				top:18%;
			}
			&.text_2{
				left:6%;
				top:44%;
			}
			&.text_3{
				left:6%;
				bottom:8%;
			}
			&.text_4{
				right:0em;
				top:18%;
			}
			&.text_5{
				right:0em;
				top:44%;
			}
			&.text_6{
				right:0em;
				bottom:8%;
			}
			&.text_7{
				display:none;
			}
			animation: slider-fadeout 3s ease;
            animation-fill-mode:forwards;
		}
		&.slick-active{
            span{
                animation: slider-fadein 3s ease;
                animation-fill-mode:forwards;
            }
        }
	}
}
@keyframes slider-fadein {
	0% {opacity:0;}
	100% {opacity:1;}
}
@keyframes slider-fadeout {
	0% {opacity:1;}
	100% {opacity:0;}
}