

/**** 歴代 ****/
.register_wrap{
}
.table_register{
	font-size:88%;
	border-top:1px solid #CCCCCC;
	border-left:1px solid #CCCCCC;
	.label{
		display:table;
		width:100%;
		text-align:center;
		background-color: #F4F8F9;
	}
	.generations{
		display:table;
		width:100%;
	}
	.detail{
		.box{
			display:table;
			width:100%;
			.check{
				display:inline-block;
				width:1.5em;
			}
			.company_wrap{
				.company{
					display:inline-block;
					width:70%;
				}
				.position{
					display:inline-block;
				}
				.current{
					font-size:81%;
				}
			}
		}
	}
	.year{
		display:table-cell;
		vertical-align:top;
		border-right:1px solid #CCCCCC;
		border-bottom:1px solid #CCCCCC;
		padding:0.3em 0.8em;
		width:20%;
		@include sp(){
			width:75px;
		}
	}
	.name{
		display:table-cell;
		vertical-align:top;
		border-right:1px solid #CCCCCC;
		border-bottom:1px solid #CCCCCC;
		padding:0.3em 0.8em;
		width:35%;
		@include sp(){
			display:block;
			width:auto;
			border-bottom:none;
		}
	}
	.company_wrap{
		display:table-cell;
		vertical-align:top;
		border-right:1px solid #CCCCCC;
		border-bottom:1px solid #CCCCCC;
		padding:0.3em 0.8em;
		@include sp(){
			display:block;
			width:auto;
			padding-top:0;
		}
	}
	
}