/**** editor ****/
.editor{
	font-size:113%;
	line-height:1.7;
	margin-bottom:5em;
	p{
		margin-bottom:1.5em;
	}
	h2{
		font-size: 150%;
		font-weight: bold;
		margin-bottom: 1em;
		margin-top: 60px;
	}
	h3{
		font-size: 113%;
		font-weight: bold;
		margin-bottom: 0.5em;
	}
	h4{
		font-size: 100%;
		font-weight: bold;
	}
	ol{
		list-style-type:decimal;
		margin-left:2em;
		margin-bottom:1.5em;
		li{
			margin-bottom:0.7em;
		}
	}
	ul{
		list-style-type:disc;
		margin-left:2em;
		margin-bottom:1.5em;
		li{
			margin-bottom:0.7em;
		}
	}
	dl{
		dt{
			font-weight:bold;
		}
		dd{
			margin-bottom:1em;
		}
	}

	*.title_style01{
		font-size:156%;
		font-weight:normal;
		border-bottom:1px solid rgba(32,33,36,0.10);
		position:relative;
		margin-bottom:20px;
		line-height:1.1;
		padding-bottom:8px;
		@include pc(){
			margin-bottom:30px;
		}
		.f_small{
			font-size:80%;
			display:inline-block;
		}
		&:after{
			content:"";
			position:absolute;
			left:0px;
			bottom:-2px;
			display:block;
			width:30px;
			height:2px;
			background-color:#F92525;
		}
	}
	
	
	*.title_style02{
		font-size:225%;
		margin-bottom:2em;
		font-weight:normal;
	}
	
	*.title_style03{
		margin-top:3em;
		color:#094387;
	}

}


