/**** title_style ****/
.title_style01{
	font-size:156%;
	font-weight:normal;
	border-bottom:1px solid rgba(32,33,36,0.10);
	position:relative;
	margin-bottom:20px;
	line-height:1.1;
	padding-bottom:8px;
	@include pc(){
		margin-bottom:30px;
	}
	.f_small{
		font-size:80%;
		display:inline-block;
	}
	&:after{
		content:"";
		position:absolute;
		left:0px;
		bottom:-2px;
		display:block;
		width:30px;
		height:2px;
		background-color:#FF6008;
	}
}


.title_style02{
	font-size:225%;
	margin-bottom:2em;
	font-weight:normal;
}

.title_style03{
	margin-top:3em;
	color:#094387;
}




.title_side{
	font-size:81%;
	background-color:#F4F8F9;
	padding:0.5em 1em;
	font-weight:normal;
	margin-bottom:1em;
}