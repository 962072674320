a.btn_style01{
	background-color:#ffffff;
	color:#202124;
	min-width:110px;
	text-align:center;
	display:block;
	font-size:81%;
	line-height:20px;
	height:20px;
	border-radius:5px;
	text-decoration:none;
	transition:all 0.2s;
	&:hover{
		background-color:#FF6008;
		color:#FFFFFF;
	}
}

a.btn_style02{
	background-color:#FF6008;
	color:#FFFFFF;
	width:100%;
	text-align:center;
	display:block;
	border-radius:4px;
	text-decoration:none;
	padding:10px 5px;
}

a.btn_style03{
	background-color:#FF6008;
	color:#FFFFFF;
	text-align:center;
	display:inline-block;
	border-radius:4px;
	text-decoration:none;
	padding:7px 20px;
	font-size:75%;
	transition:all 0.2s;
	&:hover{
		opacity:0.7;
	}
}

a.btn_style04{
	background-color:#F4F3EE;
	border:1px solid #DEDDDE;
	color:#202124;
	text-align:center;
	display:inline-block;
	border-radius:4px;
	text-decoration:none;
	padding:7px 20px;
	font-size:75%;
	transition:all 0.2s;
	&:hover{
		background-color:#FF6008;
		color:#FFFFFF;
		border:1px solid #FF6008;
	}
}

a.btn_style05{
	background-color:#FF6008;
	color:#FFFFFF;
	min-width:110px;
	text-align:center;
	display:block;
	font-size:81%;
	line-height:20px;
	height:20px;
	border-radius:5px;
	text-decoration:none;
	transition:all 0.2s;
	&:hover{
		background-color:#ffffff;
		color:#202124;
	}
}



a.icon_hang{
	.icon{
		transition: all 0.2s;
	}
	&:hover{
		.icon{
			animation-name: hvr-icon-hang-sink, hvr-icon-hang;
			animation-duration: .3s, 1.5s;
			animation-delay: 0s, .3s;
			animation-timing-function: ease-out, ease-in-out;
			animation-iteration-count: 1, infinite;
			animation-fill-mode: forwards;
			animation-direction: normal, alternate;
		}
	}
}



a.btn_submit,
input.btn_submit{
	display:inline-block;
	font-weight:bold;
	padding:15px;
	text-align:center;
	text-decoration:none;
	border:1px solid #FF6008;
	border-radius:8px;
	background-color:#FF6008;
	color:#FFFFFF;
	@include pc(){
		font-size:18px;
		transition: 0.3s;
		min-width:300px;
		opacity:0.7;
		@include hover();
		&:hover{
			background-color:#FF6008;
			cursor:pointer;
			opacity:1;
		}
	}
	@include sp(){
		box-shadow:1px 2px 2px rgba(0,0,0,0.10);
		margin-bottom:15px;
		font-size:16px;
		display:block;
		width:100%;
		box-sizing:border-box;
	}
}

a.btn_back,
input.btn_back{
	display:inline-block;
	font-weight:bold;
	padding:15px;
	text-align:center;
	text-decoration:none;
	border:1px solid #FF6008;
	border-radius:8px;
	background-color:#FFFFFF;
	color:#FF6008;
	opacity:0.7;
	@include pc(){
		font-size:18px;
		transition: 0.3s;
		min-width:300px;
		@include hover();
		&:hover{
			background-color:#FF6008;
			color:#FFFFFF;
			cursor:pointer;
			opacity:1;
		}
	}
	@include sp(){
		box-shadow:1px 2px 2px rgba(0,0,0,0.10);
		margin-bottom:15px;
		font-size:16px;
		display:block;
	}
}

.btn_wrap{
	margin:30px 0px;
	text-align:center;
}


