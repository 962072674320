footer{
	background-color:#202124;
	.fnav_wrap{
		padding:20px 0px;
		border-bottom:1px solid #979797;
		@include clearfix();
		@include pc(){
			padding:40px;
		}
		.logo{
			margin-bottom:20px;
			@include tab(){
				width:180px;
				float:left;
				//margin-right:40px;
			}
			@include pc(){
				width:200px;
				//margin-right:60px;
			}
		}
		.fnav{
			float:right;
			ul{
				@include clearfix();
				li{
					float:left;
					margin-right:20px;
					margin-bottom:20px;
					a{
						color:#FFFFFF;
						font-size:88%;
					}
					.child{
						margin-left:1em;
						margin-top:5px;
						li{
							float:none;
							margin-bottom:0px;
							a{
								color:#CCCCCC;
							}
						}
					}
				}
			}
		}
	}
	
	.btn_contact{
		margin-top:-8px;
		a{
			display:inline-block;
			padding:10px 25px;
			border:1px solid #FFFFFF;
			.fa{
				padding-right:15px;
				font-size:120%;
			}
		}
	}
	
	.copyright{
		color:#FFFFFF;
		padding:20px 0px;
		font-size:88%;
	}

}

