 /* imghvr-fold*
   ----------------------------- */
[class^='imghvr-fold'], [class*=' imghvr-fold'] {
	@include perspective(50em);
	img {
		@include transform-origin(50% 0%);
	}
	.caption {
		z-index: 1;
		opacity: 0;
	}
	&:hover {
		> img {
			opacity: 0;
			@include transition-delay(0);
		}
		.caption {
			@include transform(rotateX(0) translate3d(0,0%,0) scale(1));
			opacity: 1;
			@include transition-delay($transition-duration * 0.6);
		}
	}
}
 /* imghvr-fold-up
   ----------------------------- */
.imghvr-fold-up {
	> img {
		@include transform-origin(50% 0%);
	}
	.caption {
		@include transform(rotateX(-90deg) translate3d(0%,-50%,0) scale(0.6));
		@include transform-origin(50% 100%);
	}
	&:hover {
		> img {
			@include transform(rotateX(90deg) scale(0.6) translateY(50%));
		}
	}
}
 /* imghvr-fold-down
   ----------------------------- */
.imghvr-fold-down {
	> img {
		@include transform-origin(50% 100%);
	}
	.caption {
		@include transform(rotateX(90deg) translate3d(0%,50%,0) scale(0.6));
		@include transform-origin(50% 0%);
	}
	&:hover {
		> img {
			@include transform(rotateX(-90deg) scale(0.6) translateY(-50%));
		}
	}
}
 /* imghvr-fold-left
   ----------------------------- */
.imghvr-fold-left {
	> img {
		@include transform-origin(0% 50%);
	}
	.caption {
		@include transform(rotateY(90deg) translate3d(-50%,0%,0) scale(0.6));
		@include transform-origin(100% 50%);
	}
	&:hover {
		> img {
			@include transform(rotateY(-90deg) scale(0.6) translateX(50%));
		}
	}
}
 /* imghvr-fold-right
   ----------------------------- */
.imghvr-fold-right {
	> img {
		@include transform-origin(100% 50%);
	}
	.caption {
		@include transform(rotateY(-90deg) translate3d(50%,0%,0) scale(0.6));
		@include transform-origin(0 50%);
	}
	&:hover {
		> img {
			@include transform(rotateY(90deg) scale(0.6) translateX(-50%));
		}
	}
}