/* imghvr-push-*
   ----------------------------- */
[class^='imghvr-push-'], [class*=' imghvr-push-'] {
	&:hover {
		.caption {
			@include transform(translate(0,0));
		}
	}
}
 /* imghvr-push-up
   ----------------------------- */
.imghvr-push-up {
	.caption {
		@include transform(translateY(100%));
	}
	&:hover {
		> img {
			@include transform(translateY(-100%));
		}
	}
}
 /* imghvr-push-down
   ----------------------------- */
.imghvr-push-down {
	.caption {
		@include transform(translateY(-100%));
	}
	&:hover {
		> img {
			@include transform(translateY(100%));
		}
	}
}

 /* imghvr-push-left
   ----------------------------- */
.imghvr-push-left {
	.caption {
		@include transform(translateX(100%));
	}
	&:hover {
		> img {
			@include transform(translateX(-100%));
		}
	}
}
 /* imghvr-push--right
   ----------------------------- */
.imghvr-push-right {
	.caption {
		@include transform(translateX(-100%));
	}
	&:hover {
		> img {
			@include transform(translateX(100%));
		}
	}
}