 /* imghvr-flip-*
   ----------------------------- */
[class^='imghvr-flip-'], [class*=' imghvr-flip-'] {
	@include perspective(50em);
	img {
		backface-visibility: hidden;
	}
	.caption {
		opacity: 0;
	}
	&:hover {
		> img {
			opacity: 0;
		}
		.caption {
			opacity: 1;
			@include transition-delay($transition-duration * 0.4);
		}
	}
}
 /* imghvr-flip-horiz
   ----------------------------- */
.imghvr-flip-horiz {
	.caption {
		@include transform(rotateX(90deg));
		@include transform-origin(0% 50%);
	}
	&:hover {
		img {
			@include transform(rotateX(-180deg));
		}
		.caption {
			@include transform(rotateX(0deg));
		}
	}
}
 /* imghvr-flip-vert
   ----------------------------- */
.imghvr-flip-vert {
	.caption {
		@include transform(rotateY(90deg));
		@include transform-origin(50% 0%);
	}
	&:hover {
		> img {
			@include transform(rotateY(-180deg));
		}
		.caption {
			@include transform(rotateY(0deg));
		}
	}
}
 /* imghvr-flip-diag-1
   ----------------------------- */
.imghvr-flip-diag-1 {
	.caption {
		@include transform(rotate3d(1,-1,0,100deg));
	}
	&:hover {
		> img {
			@include transform(rotate3d(-1,1,0,100deg));
		}
		.caption {
			@include transform(rotate3d(0,0,0,0deg));
		}
	}
}
 /* imghvr-flip-diag-2
   ----------------------------- */
.imghvr-flip-diag-2 {
	.caption {
		@include transform(rotate3d(1,1,0,100deg));
	}
	&:hover {
		> img {
			@include transform(rotate3d(-1,-1,0,100deg));
		}
		.caption {
			@include transform(rotate3d(0,0,0,0deg));
		}
	}
}