.side_emblem{
	@include sp(){
		display:none;
	}
	margin-bottom:1em;
	position:relative;
	.text{
		position:absolute;
		left:0;
		bottom:0;
		padding:0.5em;
		color: #202124;
	}
}
.side_title{
	font-size:81%;
	background-color:#F4F8F9;
	padding:0.5em 1em;
	font-weight:normal;
	margin-bottom:1em;
}

.side_list{
	margin-bottom:2em;
	li{
		font-size:81%;
		padding:0.5em 1em;
		a{
			display:block;
			color:#202124;
			text-decoration:none;
			border-bottom:1px solid rgba(255,255,255,0.00);
			&:hover,
			&.active{
				border-bottom:1px solid rgba(32,33,36,0.10);
				position:relative;
				&:after{
					content:"";
					position:absolute;
					left:0px;
					bottom:-2px;
					display:block;
					width:30px;
					height:2px;
					background-color:#F92525;
				}
			}
		}
	}
}

.side_archives_wrap{
	.btn_archives{
		font-size:81%;
		padding:0.5em 1em;
		a{
			display:block;
			color:#202124;
			text-decoration:none;
			position: relative;
			&:hover{
				&:after{
					content: "\f055";
					font-family: FontAwesome;
					position:absolute;
					right:0px;
					top:0px;
					display:block;
					color: #666666;
				}
			}
			&.active{
				&:hover:after,
				&:after{
					content: "\f056";
					font-family: FontAwesome;
					position:absolute;
					right:0px;
					top:0px;
					display:block;
					color: #666666;
				}
			}
		}
	}
	.side_list{
		margin-left: 1em;
		display: none;
		&.open{
			display: block;
		}
	}
}
