.side_emblem{
	@include sp(){
		display:none;
	}
	margin-bottom:1em;
	position:relative;
	.text{
		position:absolute;
		left:0;
		bottom:0;
		padding:0.5em;
		color: #202124;
	}
}
.side_title{
	font-size:81%;
	background-color:#F4F8F9;
	padding:0.5em 1em;
	font-weight:normal;
	margin-bottom:1em;
}

.side_list{
	margin-bottom:2em;
	li{
		font-size:81%;
		padding:0.5em 1em;
		a{
			display:block;
			color:#202124;
			text-decoration:none;
			border-bottom:1px solid rgba(255,255,255,0.00);
			&:hover,
			&.active{
				border-bottom:1px solid rgba(32,33,36,0.10);
				position:relative;
				&:after{
					content:"";
					position:absolute;
					left:0px;
					bottom:-2px;
					display:block;
					width:30px;
					height:2px;
					background-color:#F92525;
				}
			}
		}
	}
}


@media print{
	body {
        overflow: visible !important;
    }
	header,
	.clumb,
	.side,
	footer,
	.sns_box,
	.link_nav_wrap{
		display:none;
	}
	.container{
		width:90%;
		margin:0px;
		padding:30px;
	}
	.content_col2 .main{
		width:auto;
		float:none;
	}
	.editor{
		margin-bottom:0;
	}
	#rs_comment_wrap .title_wrap{
		padding:0px;
	}
	.title_style01{
		margin-top:20px;
	}
}
