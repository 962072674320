@import "parts/_title.scss";
@import "parts/_editor.scss";
@import "parts/_pagination.scss";
@import "parts/_table.scss";
@import "parts/_btn.scss";
@import "parts/_list.scss";
@import "parts/_box.scss";





/**** clumb ****/
.clumb{
	margin:0 0 4.5em;
	font-size:81%;
	@include clearfix();
	li{
		float:left;
			&:after{
				content: "\f105";
				font-family: FontAwesome;
				padding:0px 1em;
				color:#202124;
			}
			&:nth-last-of-type(1){
				&:after{
					display:none;
				}
			}
		a{
			color:#094387;
		}
	}
}


/**** pdf ****/
.pdf_wrap{
	background-color:#F4F8F9;
	padding:2em;
	margin-bottom:5em;
	h2{
		font-size:113%;
		font-weight:bold;
		border-bottom:1px dashed #202124;
		margin-bottom:2.5em;
	}
	h3{
		font-size:88%;
		margin-top:2.5em;
		font-weight:normal;
		margin-bottom:0.5em;
	}
	.btn{
		margin-bottom:0.5em;
		a.btn_pdf{
			display:inline-block;
			color:#0A0E5A;
			text-decoration:none;
			padding:0.7em 5em 0.7em 1.5em;
			border:1px solid  #9B9B9B;
			border-radius:4px;
			background-color:#FFFFFF;
			font-size:88%;
			position:relative;
			&:after{
				content:"";
				display:block;
				width:2em;
				height:2em;
				position:absolute;
				right:1.5em;
				top:50%;
				margin-top:-1em;
				background:url(../images/icon_pdf.png) no-repeat right center;
				background-size:2em 2em;
				transform: translateZ(0);
				transition-duration: 0.3s;
				transition-property: transform;
				transition-timing-function: ease-out;
			}
			&:hover{
				&:after{
					transform: scale(1.2) translateZ(0);
				}
			}
		}
	}
}


/**** more_style ****/
.more_style01{
	text-align:right;
	font-size:75%;
	.fa{
		color: #202124;
	}
}



/**** sns_box ****/
.sns_box{
	margin-bottom:1em;
	@include clearfix();
	ul{
		display:table;
		float:right;
		li{
			display:table-cell;
			vertical-align:top;
			padding-left:1em;
			height:20px;
			.fb_iframe_widget span{
				vertical-align:top !important;
			}
		}
	}
}


/*** sidebar ***/
.year_emblem{
	background-color: #202124;
	color:#FFFFFF;
	vertical-align:middle;
	text-align:center;
	width:100%;
	height:10em;
	line-height:10em;
	font-size:113%;
}

	

.wrap{
	.date{
		font-size:88%;
		color:#D0021B;
		margin-bottom:0.5em;
		span{
			color:#202124;
			padding-left:1em;
		}
		span.icon_new{
			display: inline-block;
			background-color: #D0021B;
			color: #FFFFFF;
			border-radius: 4px;
			margin-left: 1em;
			padding: 0em 1em;
		}
	}
}

.visual{
	margin-bottom:2em;
}

.comment_katagaki{
	line-height:1.7;
	text-align:right;
	margin-bottom:3em;
	p{
		margin-bottom:0;
	}
}

.ijo{
	margin-top:3em;
	text-align:right;
}



/**** fancybox ****/
.fancybox-skin{
	padding:0px !important;
	box-shadow:0px 0px 20px rgba(0,0,0,0.30);
}
.fancybox-lock #wrapper{
	  -webkit-filter: blur(5px);
	  filter: blur(5px);
}
#fancybox-loading, a.fancybox-close, .fancybox-prev span, .fancybox-next span{
    background-image: url('../js/fancybox/fancybox_sprite@2x.png');
}




/**** link_nav_wrap ****/
.link_nav_wrap{
	@include clearfix();
	a{
		font-size:14px;
		text-decoration:none;
		display:block;
		padding:10px;
		border:1px solid #E1E1E1;
		text-align:center;
		//margin-top:20px;
		margin-bottom:10px;
		background-color:#FFFFFF;
		color: #000000;
		transition:all 0.3s;

		@include pc(){
			display:inline-block;
			padding:5px 20px;
			margin-top:10px;
			font-size:16px;
		}
		.fa{
			color:#FF6008;
			font-size:20px;
			display:inline-block;
			margin-top:-2px;
			vertical-align:middle;
		transition:all 0.3s;
		}
		&:hover{
			background-color:#FF6008;
			color:#FFFFFF;
			border:1px solid #FF6008;
			.fa{
				color:#FFFFFF;
			}
		}
	}
	.left{
		//float:left;
		display:block;
		text-align:left;
		a{
			display:table;
			text-align:left;
			width:100%;
			.fa{
				display:table-cell;
				padding-right:7px;
			}
		}
	}
	.right{
		//float:right;
		display:block;
		text-align:right;
		a{
			display:table;
			text-align:right;
			width:100%;
			.fa{
				display:table-cell;
				padding-left:7px;
			}
		}
	}
}
