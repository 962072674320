 /* imghvr-blur
   ----------------------------- */
.imghvr-blur {
	.caption {
		opacity: 0;
	}
	&:hover {
		> img {
			@include filter(blur(30px));
			@include transform(scale(1.2));
			opacity: 0;
		}
		.caption {
			opacity: 1;
			@include transition-delay($transition-duration * 0.6);
		}
	}
}